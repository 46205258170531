@import '@styles/variables';
@import '@styles/mixin';

/** Select Box */

.selectGoodCause .select__control {
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 10px;
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
	appearance: none;
	color: #fff;
	border-color: $peach;
	padding-left: 5px;
	cursor: pointer;
	height: 50px;
	border: solid 1px $peach;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
}
.selectGoodCause .select__control:hover {
	border-color: $peach;
}
.selectGoodCause .select__control--is-focused {
	border-color: $peach;
	box-shadow: none;
}
.selectGoodCause .select__placeholder {
	color: #000;
}
.selectGoodCause .select__dropdown-indicator {
	color: $peach;
}
.selectGoodCause .select__indicator-separator {
	background-color: $peach;
}
.selectGoodCause .select__clear-indicator {
	color: $peach;
}
.selectGoodCause .select__menu .select__menu-list .select__option {
	color: #000;
	text-align: left;
}
.selectGoodCause .select__menu .select__menu-list .select__option:active {
	color: #fff;
	background-color: #2684ff;
}
.selectGoodCause .select__menu .select__menu-list .select__option:hover {
	color: #fff;
	background-color: #2684ff;
}
.selectGoodCause .select__menu .select__menu-list .select__option--is-selected {
	color: #fff;
}
