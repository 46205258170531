/** @format */

@import '@styles/variables';
@import '@styles/mixin';

* {
	scrollbar-width: thin;
	scrollbar-color: #7f7f7f #fff;
}
::-webkit-scrollbar {
	-webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
	width: 5px;
}
::-webkit-scrollbar:horizontal {
	height: 5px;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(65, 65, 65, 0.5);
	border-radius: 10px;
}
::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}
html {
	@include html;
}
body {
	@include body;
}
img {
	@include img;
}
p,
pre {
	color: #7f7f7f;
	font-size: $font14;
	font-weight: $font-regular;
	line-height: 21px;
	padding-bottom: 15px;
	font-family: $base-font;
	margin-bottom: 0;
}
ul,
ol,
ul,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}
a {
	color: $black;
	&:hover,
	&:focus {
		color: $black;
	}
}
b,
strong {
	font-weight: $font-bold;
}
a {
	text-decoration: none;
	@include transition(all 0.3s ease);
	&:hover {
		text-decoration: none;
	}
}

.container {
	max-width: 1600px;
	@media screen and (max-width: $custom_xxl) {
		max-width: 100%;
		padding: 0 $pad50;
	}
	@media screen and (max-width: $custom_xl) {
		padding: 0 $pad15;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	line-height: 100%;
	margin-top: 0;
	margin-bottom: 20px;
	padding: 0;
	color: $black;
	font-family: $base-font;
	font-weight: $font-regular;
}
h1,
.h1 {
	font-size: $headingOne;
}
h2,
.h2 {
	font-size: $headingTwo;
}
h3,
.h3 {
	font-size: $headingThree;
}
h4,
.h4 {
	font-size: $headingFour;
}
h5,
.h5 {
	font-size: $headingFive;
}
h6,
.h6 {
	font-size: $headingSix;
}

::-moz-selection {
	color: $white;
	background-color: $overcast;
}
::-webkit-selection {
	color: $white;
	background-color: $overcast;
}
::selection {
	color: $white;
	background-color: $overcast;
}

.btnCustom {
	@include btnCustom;
}

.form-control {
	&:focus {
		outline: none;
		box-shadow: none;
	}
	@media screen and (max-width: $custom_sm) {
		font-size: $font16 !important;
		&::-webkit-input-placeholder {
			font-size: 14px !important;
			font-weight: 500 !important;
			@media screen and (max-width: $custom_xs) {
				font-size: 13px !important;
			}
			@media screen and (max-width: 367px) {
				font-size: 12px !important;
			}
		}
		&::-moz-placeholder {
			font-size: 14px !important;
			font-weight: 500 !important;
			@media screen and (max-width: $custom_xs) {
				font-size: 13px !important;
			}
			@media screen and (max-width: 367px) {
				font-size: 12px !important;
			}
		} /* Firefox 19+ */
		&:-moz-placeholder {
			font-size: 14px !important;
			font-weight: 500 !important;
			@media screen and (max-width: $custom_xs) {
				font-size: 13px !important;
			}
			@media screen and (max-width: 367px) {
				font-size: 12px !important;
			}
		} /* Firefox 18- */
		&:-ms-input-placeholder {
			font-size: 14px !important;
			font-weight: 500 !important;
			@media screen and (max-width: $custom_xs) {
				font-size: 13px !important;
			}
			@media screen and (max-width: 367px) {
				font-size: 12px !important;
			}
		} /* IE */
	}
}

.form-control {
	@include form-control;
}

select {
	&.form-control {
		@include select_box;
	}
}

.container2 {
	display: block;
	cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
	width: 18px;
	height: 2px;
	background-color: $black;
	margin: 4px 0;
	transition: 0.4s;
}
.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-2px, 2px);
	transform: rotate(-45deg) translate(-2px, 2px);
}
.change .bar2 {
	opacity: 0;
}
.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-8px, -8px);
	transform: rotate(45deg) translate(-8px, -8px);
}
.menuImage {
	display: none;
	@media screen and (max-width: $custom_md) {
		display: $d-block;
	}
}

.breadcums {
	padding-bottom: $pad30;
	@media screen and (max-width: $custom_md) {
		padding-bottom: $pad20;
	}
	li {
		font-size: $font14 + 1;
		font-family: $base-font;
		color: #93c572;
		position: $relative;
		margin-right: $mrg15;
		@media screen and (max-width: $custom_sm) {
			font-size: $font14 - 2;
			margin-right: $mrg5;
		}
		&::after {
			content: '>';
			color: #7c7c7c;
			font-size: 16px;
			padding-left: 15px;
			position: $relative;
			top: 1px;
			@media screen and (max-width: $custom_sm) {
				padding-left: 5px;
			}
		}
		&:last-child {
			margin-right: 0;
			color: $pistachio;
			@media screen and (max-width: $custom_sm) {
				margin-top: 2px;
				display: inline-flex;
			}
			&::after {
				display: none;
			}
			a {
				color: $pistachio !important;
				&:hover {
					color: $pistachio !important;
				}
			}
		}
		a {
			color: $black;
			&:hover {
				color: $overcast;
			}
		}
	}
}

/* The container */
.custom-check {
	display: block;
	position: relative;
	padding-left: 28px;
	margin-bottom: 0;
	cursor: pointer;
	font-size: $font14 - 1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $black;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: $white;
		border: 1px solid #dedede;
		&::after {
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 4px;
			width: 8px;
			height: 8px;
			content: '';
			position: absolute;
			display: none;
			background-color: $overcast;
		}
	}
}
.custom-check input:checked ~ .checkmark:after {
	display: block;
}

.paginationOuter {
	padding: $pad60 0;
	.pagination {
		@media screen and (max-width: $custom_md) {
			justify-content: center;
		}
		li {
			width: 40px;
			margin-left: $mrg5 - 2;
			cursor: pointer;
			a {
				font-size: 14px;
				height: 40px;
				line-height: 26px;
				color: $black;
				border-radius: 50%;
				display: $d-block;
				padding: 6px 12px;
				border: none;
				text-align: center;
				text-transform: $uppercase;
				&:hover {
					background-color: inherit;
				}
				@media screen and (max-width: 400px) {
					height: 37px;
					line-height: 24px;
				}
				@media screen and (max-width: 380px) {
					height: 33px;
					line-height: 22px;
				}
				@media screen and (max-width: 373px) {
					height: 32px;
					line-height: 20px;
				}
				@media screen and (max-width: 365px) {
					height: 29px;
					line-height: 17px;
				}
			}
			&:first-child {
				width: auto;
				a {
					font-weight: $font-semibold;
					border-radius: 0;
					color: $overcast;
				}
			}
			&:last-child {
				width: auto;
				a {
					font-weight: $font-semibold;
					border-radius: 0;
					color: $overcast;
				}
			}
			&.active {
				a {
					background-color: $overcast;
				}
			}
		}
	}
	p {
		color: #7f7f7f;
		font-size: $font14;
		padding: 0;
		text-align: right;
		@media screen and (max-width: $custom_md) {
			text-align: center;
			padding: $pad30 0 0;
		}
	}
	.row {
		.col-md-12 {
			.d-md-flex {
				@media screen and (max-width: $custom_md) {
					justify-content: center !important;
				}
			}
		}
	}
}

.checkout-radio {
	display: block;
	position: relative;
	padding: $pad15 $pad60 + 10 $pad15 $pad40;
	margin-bottom: 0;
	cursor: pointer;
	font-size: $font14 - 1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $black;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 15px;
		left: 10px;
		height: 22px;
		width: 22px;
		background-color: $white;
		border: 2px solid #757575;
		border-radius: 50%;
		&::after {
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 3px;
			width: 12px;
			height: 12px;
			content: '';
			position: absolute;
			display: none;
			background-color: $orange;
			border-radius: 50%;
		}
	}
}
.checkout-radio input:checked ~ .checkmark {
	border-color: $orange;
}
.checkout-radio input:checked ~ .checkmark:after {
	display: block;
}

label {
	display: $d-block;
	margin-bottom: $mrg5 + 3;
	color: $black-CP;
	font-size: $font14 - 2;
	font-family: $base-font;
	font-weight: $font-semibold;
}

.quantity {
	width: 100%;
	position: $relative;
	.input-text {
		&.qty {
			width: 100%;
			height: 40px;
			padding: 0;
			text-align: center;
			outline: none;
			border: none;
			font-size: $font16;
			font-weight: $font-medium;
			color: $black;
			font-family: $base-font;
			@include bdr-rds($radius: 50px);
			border: solid 1px $blackNew;
		}
	}
	&.buttons_added {
		.minus,
		.plus {
			padding: 0;
			height: 40px;
			width: 40px;
			border: none;
			cursor: pointer;
			color: #787878;
			font-size: $font14;
			outline: none;
			font-weight: $font-semibold;
			background: none;
			position: $absolute;
			top: 0;
			bottom: 0;
		}
		.minus {
			left: 0;
		}
		.plus {
			right: 0;
		}
	}
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
}

.cuppon-message {
	border: solid 1px transparent;
	@include bdr-rds($radius: 4px);
	font-size: $font14;
	font-weight: $font-medium;
	padding: $pad5 $pad15;
	margin-bottom: $mrg15;
	i {
		display: inline-block;
		margin-right: $mrg5;
	}
	&.success {
		background: #f8fff6;
		color: #24be00;
		border-color: #24be00;
	}
	&.error {
		background: #fff2f4;
		color: #ff3359;
		border-color: #ff3359;
	}
}
.modal {
	display: none;
	background: rgba(0, 0, 0, 0.4);
}
.custom-popup {
	.modal-dialog {
		max-width: 1180px;
		.modal-content {
			@include bdr-rds($radius: 4px);
			border: none;
			box-shadow: 0px 4px 8px #00000029;
			padding: 0;
		}
	}
	.close {
		position: absolute;
		right: 25px;
		top: 20px;
		opacity: 1;
		outline: none;
		z-index: 99;
		@media screen and (max-width: $custom_md) {
			right: 15px;
			top: 18px;
		}
	}
}
.close {
	opacity: 1;
}

.good-cause-hd {
	text-align: center;
	padding: $pad20 0 $pad30;
	@media screen and (max-width: $custom_md) {
		padding: $pad40 0 $pad30;
	}
	h1 {
		font-family: $base-font;
		font-size: $font22 * 2 - 2;
		font-weight: $font-semibold;
		padding-bottom: $pad30;
		margin-bottom: 0;
		@media screen and (max-width: $custom_md) {
			font-size: 34px;
		}
	}
	p {
		font-weight: $font-medium;
		font-size: $font14;
		color: #757575;
		line-height: 24px;
		font-family: $base-font;
	}
}

.good-cause-list {
	padding: 0 $pad10;
	.row {
		margin-right: -10px;
		margin-left: -10px;
		.col-md-3 {
			padding-right: $pad10;
			padding-left: $pad10;
		}
	}
	.activeCard {
		box-shadow: 0px 3px 12px #0000004d;
		border-color: $orange !important;
	}
	.good-cause-card {
		border: solid 1px #cccccc;
		padding: $pad20;
		margin-bottom: $mrg20;
		height: 300px;
		@include bdr-rds($radius: 4px);
		@include transition(all 0.3s ease);
		cursor: pointer;
		&:hover {
			box-shadow: 0px 3px 12px #0000004d;
			border-color: $orange;
		}

		h6 {
			font-size: $font12 + 1;
			font-family: $base-font;
			font-weight: $font-semibold;
			margin-bottom: $mrg5 + 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			min-height: 26px;
			text-transform: capitalize;
		}
		.good-cause-img {
			margin-bottom: $mrg5 + 2;
			min-height: 77px;
			@media screen and (max-width: $custom_md) {
				min-height: auto;
			}
			img {
				display: block;
				margin: 0 auto;
			}
		}
		p {
			padding: 0;
			font-weight: $font-medium;
			font-size: $font14 - 3;
			color: #757575;
			font-family: $base-font;
			line-height: 16px;
		}
	}
}
.good-cause-save {
	max-width: 360px;
	margin: $mrg15 auto;
	height: 57px;
	line-height: 57px;
	font-weight: $font-medium;
}

.xs-popup {
	@media screen and (max-width: $custom_md) {
		padding: $pad15;
	}
	.modal-dialog {
		max-width: 350px;
		margin: 17rem auto;
		@media screen and (max-width: $custom_md) {
			margin: 14rem auto;
		}
	}
}

.small-popup {
	@media screen and (max-width: $custom_md) {
		padding: $pad15;
	}
	.modal-dialog {
		max-width: 568px;
		margin: 7rem auto;
		@media screen and (max-width: $custom_md) {
			margin: 5rem auto;
		}
	}
}

.medium-popup {
	@media screen and (max-width: $custom_md) {
		padding: $pad15;
	}
	.modal-dialog {
		max-width: 767px;
		margin: 7rem auto;
		@media screen and (max-width: $custom_md) {
			margin: 5rem auto;
		}
	}
}

.added-bag {
	h2 {
		font-size: $font18;
		font-weight: $font-semibold;
		font-family: $base-font;
		margin-bottom: $mrg25;
		padding-bottom: $pad20;
		border-bottom: solid 1px #e5e5e5;
	}
	.added-bag-out {
		padding-bottom: $pad30;
		.added-bag-img {
			width: 200px;
			margin-right: $mrg20;
			@media screen and (max-width: $custom_md) {
				width: 125px;
			}
			img {
				display: block;
			}
		}
		.added-bag-info {
			h6 {
				font-size: $font14;
				font-weight: $font-semibold;
				font-family: $base-font;
				margin-bottom: $mrg15;
			}
			.mit {
				display: $d-block;
				color: #9e9e9e;
				font-size: $font14 - 2;
				font-weight: $font-semibold;
				font-family: $base-font;
				padding-bottom: $pad5 + 3;
			}
			p {
				color: #757575;
				font-size: $font14 - 1;
				font-weight: $font-medium;
				font-family: $base-font;
				padding-bottom: $pad5;
				span {
					color: $black;
				}
			}
		}
	}
	.added-bag-btn {
		.btnCustom {
			width: 47%;
			@media screen and (max-width: $custom_md) {
				width: 100%;
				height: 56px;
				line-height: 56px;
			}
			&.black-bt {
				background: $black;
				@media screen and (max-width: $custom_md) {
					margin-bottom: $mrg25;
				}
			}
		}
	}
}

.joinPopup {
	.form-control {
		padding-left: $pad45;
	}
}

.links {
	.link_type_1 {
		font-family: $base-font;
		font-weight: 600;
		font-size: 11px;
		text-transform: $uppercase;
		position: $relative;
		padding: $pad20 $pad0;
		display: $d-block;
		color: $black;
		cursor: $pointer;
		.img_arrow {
			font-size: $font22;
			position: $relative;
			top: 2px;
			right: -6px;
			display: $inline-block;
			width: 12px;
			height: 10px;
		}
	}
	.link_type_2 {
		color: $white;
		letter-spacing: 1px;
		padding: $pad5 $pad10;
		font-size: 11px;
		display: $d-block;
		font-family: $base-font;
		font-weight: $font-medium;
		line-height: 1;
		cursor: $pointer;
		&:hover {
			background-color: $white;
			color: $red;
		}
	}
	.link_type_3 {
		cursor: $pointer;
		font-family: $base-font-six;
		font-weight: $font-medium;
		font-size: $font14;
		text-transform: $uppercase;
		color: $black;
		display: $d-block;
		padding: 14.5px $pad0;
		position: $relative;
		transition: $none;
		-webkit-transition: $none;
		@media screen and (max-width: $custom_xl) {
			font-size: $font12;
		}
		&:hover {
			color: $red;
			margin: 0px auto;
			text-align: center;
			&:after {
				position: $absolute;
				left: 0;
				right: 0;
				bottom: 10px;
				margin: $mrg0 $mrgauto;
				content: '';
				background-color: $red;
				height: 2px;
				width: calc(100% - 12px);
				opacity: 1;
				margin: 0px auto;
				text-align: center;
			}
		}
	}
	.link_active {
		color: $red;
		&:after {
			position: $absolute;
			left: 0;
			right: 0;
			bottom: 10px;
			margin: $mrg0 $mrgauto;
			content: '';
			background-color: $red;
			height: 2px;
			width: calc(100% - 12px);
			opacity: 1;
			margin: 0px auto;
			text-align: center;
		}
	}
	.link_type_4 {
		color: $red;
		cursor: $pointer;
	}
	.link_type_5 {
		color: $red;
		cursor: $pointer;
		text-decoration: underline;
	}
	.link_type_6 {
		color: $orange !important;
		cursor: $pointer;
		border-bottom: 1px solid $orange;
		i {
			color: $orange !important;
		}
	}
	.link_type_7 {
		cursor: $pointer;
		font-family: $base-font-three;
		font-weight: $font-semibold;
		font-size: $font16;
		text-transform: $uppercase;
		color: $black;
		position: $relative;
		i {
			padding-right: 7px;
		}
		@media screen and (max-width: $custom_xl) {
			font-size: $font12;
		}
	}
	.cursor_pointer {
		cursor: $pointer;
	}
	.link_type_8 {
		color: $overcast;
		cursor: $pointer;
	}
	.link_type_9 {
		color: $blackNew;
		cursor: $pointer;
		border-bottom: 1px solid $blackNew;
	}
	.link_type_10 {
		color: $peach;
		cursor: $pointer;
		border-bottom: 1px solid $peach;
	}
	.link_moss {
		color: $moss;
		cursor: $pointer;
	}
	.link_overcast {
		color: $overcast;
		cursor: $pointer;
		border-bottom: 1px solid $overcast;
	}
}

.carousel-control-next,
.carousel-control-prev {
	top: 50%;
	color: #000;
	height: 40px;
	font-size: 18px;
	&:hover,
	&:active,
	&:focus,
	&:visited {
		color: #000;
	}
}

.listing-page-block .listing-filter {
	padding-top: 65px;
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
	padding-right: 30px;
}

.listing-page-block .listing-filter .filter-list-card .card-header {
	padding: 0;
	border-radius: 0;
	border: none;
	background: none;
}

.listing-page-block .listing-filter .filter-list-card .card-header a {
	color: #000;
	padding: 15px 0;
	/*background: #f5f5f5;*/
	display: block;
	font-size: 15px;
	border-bottom: 1px solid transparent;
	position: relative;
	font-weight: 600;
}

.listing-page-block .listing-filter .filter-list-card .card-header a:after {
	position: absolute;
	content: '\F054';
	right: 2px;
	top: 14px;
	color: #000;
	font-family: 'Font Awesome 5 Free';
	font-size: 16px;
	font-weight: 900;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.listing-page-block .listing-filter .filter-list-card .card-header a:before {
	position: absolute;
	content: '';
	left: 0;
	bottom: -1px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	width: 70px;
	height: 3px;
	background: #f05024;
	opacity: 0;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.card-header
	a[aria-expanded='true'] {
	border-color: #ddd;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.card-header
	a[aria-expanded='true']:after {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.card-header
	a[aria-expanded='true']:before {
	opacity: 1;
}

.listing-page-block .listing-filter .filter-list-card .level-menu {
	padding: 10px 0;
}

.listing-page-block .listing-filter .filter-list-card .level-menu ul li a {
	color: #000;
	padding: 12px 12px;
	display: block;
	font-size: 14px;
	position: relative;
}

.listing-page-block .listing-filter .filter-list-card .level-menu ul li a i {
	position: absolute;
	right: 12px;
	top: 15px;
	font-size: 14px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li
	a:hover {
	background: #f5f5f5;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li
	a[aria-expanded='true'] {
	font-weight: 700;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li
	a[aria-expanded='true']
	i {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li:last-child
	a {
	border-bottom: 1px solid transparent;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li
	.level-menu-two
	li
	a {
	padding-left: 25px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li
	.level-menu-two
	li
	.level-menu-three
	li {
	padding: 12px 12px 12px 25px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li.active
	a {
	font-weight: 700;
	padding-left: 30px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.level-menu
	ul
	li.active
	a
	i {
	left: 11px;
	right: auto;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.listing-page-block .listing-filter .filter-list-card .price-filter {
	padding: 25px 0 20px;
}

.listing-page-block .listing-filter .filter-list-card .retailer-filter {
	padding: 25px 10px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.search-filter {
	position: relative;
	margin: 0 0 15px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.search-filter
	.src-icon-ft {
	position: absolute;
	width: 20px;
	top: 15px;
	left: 12px;
	color: #000;
	border: none;
	outline: none;
	background: none;
	padding: 0;
	cursor: pointer;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.search-filter
	.form-control {
	padding-left: 40px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.check-list {
	max-height: 235px;
	overflow: auto;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.check-list
	ul
	li {
	padding: 14px 12px;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.check-list
	ul
	li:hover {
	background: #f5f5f5;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.check-list
	.see-btn {
	display: block;
	background-color: #ddd;
	color: #000;
	font-size: 14px;
	text-align: center;
	padding: 5px 0 0;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.check-list
	.see-btn
	span {
	display: block;
}

.listing-page-block
	.listing-filter
	.filter-list-card
	.retailer-filter
	.check-list
	.see-btn:hover {
	background: #4d4545;
}

#nprogress .bar {
	background: $grey !important;
}

#nprogress .spinner .spinner-icon {
	border-top-color: $grey;
	border-left-color: $grey;
}

//-------Range Slider-----
.slider {
	position: relative;
	width: 250px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
	position: absolute;
}

.slider__track,
.slider__range {
	border-radius: 3px;
	height: 5px;
}

.slider__track {
	background-color: #ced4da;
	width: 100%;
	z-index: 1;
}

.slider__range {
	background-color: $orange;
	z-index: 2;
}

.slider__left-value,
.slider__right-value {
	color: #dee2e6;
	font-size: 12px;
	margin-top: 20px;
}

.slider__left-value {
	left: 6px;
}

.slider__right-value {
	right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}

.thumb {
	pointer-events: none;
	position: absolute;
	height: 0;
	width: 250px;
	outline: none;
}

.thumb--zindex-3 {
	z-index: 3;
}

.thumb--zindex-4 {
	z-index: 4;
}

.thumb--zindex-5 {
	z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

.swiper-button-prev,
.swiper-button-next {
	color: $black !important;
}
.errMsg {
	font-size: 14px;
	font-weight: bold;
	text-transform: capitalize;
	padding: 10px;
}
.textCapitalize {
	text-transform: capitalize;
}
.disabled {
	cursor: no-drop !important;
	color: $grey !important;
}
.collapse:not(.show) {
	background: red;
	transform: translate3d(0px, 0px, 0px);
	transition-duration: 300ms;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-property: height;
	height: 0px;
	overflow: hidden;
	display: block !important;
	visibility: hidden;
}
.collapse:not(.show) + div {
	opacity: 0;
	transition: opacity 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}

.collapse.show {
	// height: 300px;
	overflow: inherit;
	display: block !important;
	visibility: visible;
}
.collapse.show + div {
	opacity: 1;
}

.mb-5 {
	margin-bottom: 5px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-20 {
	margin-bottom: 20px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.ml-5 {
	margin-left: 5px !important;
}
.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
	position: absolute;
	content: '';
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[x-placement^='bottom'] .tooltip-arrow {
	top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .tooltip-arrow::before {
	bottom: 0;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.pd_10 {
	padding: $pad10 !important;
}
.viewPortWithFooter {
	min-height: calc(100vh - 946px);
}
.viewPortWithOutFooter {
	min-height: auto;
}

iframe {
	max-width: 100%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
.odometer-digit {
	&:last-child {
		display: none !important;
	}
	.odometer-digit-inner {
		text-align: center !important;
	}
}

// AUD Price Message
.audPriceMessage {
	display: block;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	background-color: $overcast;
	color: #fff;
	border-radius: 20px;
	padding: 5px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 10px;
	font-weight: $font-bold;
	margin-bottom: 15px;
	width: fit-content;
	i {
		margin-right: 10px;
	}
}
.disableBtn {
	@include disableBtn;
}

.toolTip {
	position: relative;
	.item {
		cursor: pointer;
	}
	span {
		visibility: hidden;
		position: absolute !important;
		min-width: 300px;
		left: 15px;
		background: $white !important;
		color: $black !important;
		font-weight: $font-regular !important;
		border-radius: 5px !important;
		border: 1px solid #a8a2a2 !important;
		top: -22px;
		z-index: 9;
		height: 18px !important;
		line-height: 16px !important;
		text-align: left !important;
		padding-left: 5px !important;
		z-index: 9;
		@media screen and (max-width: $custom_sm) {
			min-width: 300px;
			left: 0;
		}
		@media screen and (max-width: 350px) {
			min-width: 200px;
		}
		&:hover,
		&:focus {
			color: $white !important;
		}
	}
	.item:hover + span,
	.item:active + span {
		visibility: visible;
	}
}
/* Store listine Page */
.storeCol:not(.show) {
	transform: translate3d(0px, 0px, 0px);
	transition-duration: 300ms;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-property: height;
}
.storeCol.show {
	overflow: inherit;
	display: block !important;
	visibility: visible;
}
.storeCol:not(.show) + div {
	opacity: 1;
	transition: opacity 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}
.storeCol.show + div {
	opacity: 1;
	transition: opacity 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}

.accordionCustom {
	padding-top: $pad30;
	padding-bottom: $pad30;
	.card {
		border: none;
		.card-header {
			padding: 0;
			background: inherit;
			border-bottom: solid 1px #c8c8c8;
			margin-bottom: 0;
			background: url('#{$image-path}/plusIcon.svg') no-repeat center right;
			&.cardheadershow {
				background: url('#{$image-path}/minusIcon.svg') no-repeat center right;
				h2 {
					color: $blackLight;
				}
			}
			h2 {
				font-size: $font20;
				color: rgba($black, 0.4);
				padding: $pad15 0;
				border: none;
				cursor: pointer;
				margin: 0;
			}
		}
		.card-body {
			padding: $pad10 $pad50 $pad10 0;
			font-size: $font14;
			color: $black;
			@media screen and (max-width: $custom_md) {
				padding: $pad10 0 $pad10 0;
			}
		}
	}
}

.bidSecRtNrt {
	@media screen and (max-width: $custom_xss) {
		margin: $mrg10 $mrg0;
	}
	@media screen and (max-width: $custom_md) {
		padding: $pad10 0 0 0;
		margin-left: 0;
	}
	ul {
		align-items: center;
		@media screen and (max-width: $custom_xss) {
			.marginright {
				margin-right: $mrg40;
			}
		}
		li {
			margin-right: $mrg30;
			font-weight: $font-semibold;
			font-size: $font15;
			line-height: 1;
			color: #a4a4a4;
			cursor: $pointer;

			span {
				display: block;
				color: $blackLight;
			}
			a {
				display: flex;
				align-items: center;
				color: #a4a4a4;
				img {
					margin-right: $mrg10;
				}
			}
		}
	}
}

.bidSecRtShare {
	margin-left: -60px;
	@media screen and (max-width: $custom_md) {
		padding: $pad10 0 0 0;
		margin-left: 0;
	}
	@media screen and (max-width: $custom_xss) {
		margin: $mrg10 $mrg0;
	}
	span {
		cursor: $pointer;
		display: block;
		color: $blackLight;
		font-weight: $font-semibold;
		margin-right: $mrg30;
		font-size: $font15;
		i {
			color: #a4a4a4 !important;
			font-size: $font16;
		}
	}
}
.noFoundBlock {
	.alert-danger {
		color: $moss !important;
		background-color: $peach;
		border-color: $peach;
	}
	.text-danger {
		color: $moss !important;
	}
}
.text-overcast {
	color: $overcast !important;
}
.mossBtn {
	background-color: $moss !important;
	color: $white !important;
	&:hover {
		background-color: rgba($moss, 0.9) !important;
	}
}
.peachBtn {
	background-color: $peach !important;
	color: $moss !important;
	&:hover {
		background-color: rgba($peach, 0.85) !important;
	}
}
.overcastBtn {
	background-color: $overcast !important;
	color: $white !important;
	&:hover {
		background-color: rgba($overcast, 0.9) !important;
	}
}
.wishlistDanger {
	background-color: $moss;
}

.shadow {
	border-radius: 5px;
	padding: $pad15;
}
.shadowAuction {
	padding: $pad0;
}
.borderBox {
	padding: $pad15;
	border: 1px solid $grey-two;
}
.borderBoxsAuction {
	padding: $pad0;
}
.pad15 {
	padding: $pad15;
}
.customCol {
	flex: 0 0 25%;
	max-width: 25%;
	padding-left: 15px;
	padding-right: 15px;
	@media screen and (min-width: 0px) {
		flex: 0 0 100%;
		max-width: 100%;
	}
	// @media screen and (min-width: 350px) {
	// 	flex: 0 0 50%;
	// 	max-width: 50%;
	// }
	// @media screen and (min-width: $custom_xsm) {
	// 	flex: 0 0 50%;
	// 	max-width: 50%;
	// }
	// @media screen and (min-width: $custom_sm) {
	// 	flex: 0 0 33.33%;
	// 	max-width: 33.33%;
	// }
	@media screen and (min-width: $custom_sm) {
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}
	@media screen and (min-width: $custom_md) {
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}
	// @media screen and (min-width: $custom_xl) {
	// 	flex: 0 0 33.33%;
	// 	max-width: 33.33%;
	// }
	@media screen and (min-width: $custom_xl) {
		flex: 0 0 25%;
		max-width: 25%;
	}
	@media screen and (min-width: $custom_xxl) {
		flex: 0 0 25%;
		max-width: 25%;
	}
	&.wishlistCOl {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
}
.moss {
	color: $moss;
}
.inlineBlock {
	display: inline-block !important;
}
.unset {
	display: unset !important;
}

.guestCountryInput {
	.selectedCountryCustom {
		top: 8px !important;
	}
	.selectCountry {
		li {
			text-align: left;
		}
	}
}
.textCenter {
	text-align: center !important;
}
