/** @format */

@import '@styles/variables';
@import '@styles/mixin';

.closeImg {
	position: $relative;
	height: 20px;
	width: 20px;
}
.loaderThree {
	position: $absolute;
	height: 40px;
	width: 40px;
	display: inline-block;
}
.loaderTwo {
	position: $absolute;
	height: 40px;
	width: 40px;
	display: inline-block;
	right: 10px;
}
.customIcon {
	position: $relative;
	height: 20px;
	width: 20px;
	vertical-align: middle;
	display: inline-block;
}
.loadingModal {
	justify-content: center;
	display: flex;
	height: 100vh;
	background: rgb(255 255 255 / 0);
	backdrop-filter: blur(8px);
	@media screen and (max-width: $custom_md) {
		padding: $pad15;
	}
	.modalDialog {
		max-width: 250px;
		position: relative;
		margin: auto;
		.modalContent {
			height: 250px;
			width: 250px;
			text-align: center;
			padding: 5px;
			background-color: transparent !important;
			box-shadow: none !important;
			.cardBody {
				padding: 0;
				background-color: transparent !important;
				.image {
					height: 200px;
					width: 200px;
					position: relative;
					text-align: center;
					display: inline-block;
				}
			}
		}
	}
}

.linkIcon {
	a {
		display: block;
		padding: 9.5px 0;
		i {
			display: inline-block;
			width: 24px;
			.image {
				span {
					img {
						top: 3px !important;
					}
				}
			}
		}
	}
}
