// Stacked Icons
// -------------------------

.#{$fa-css-prefix}-stack {
	display: inline-block;
	height: 2em;
	line-height: 2em;
	position: relative;
	vertical-align: middle;
	width: ($fa-fw-width * 2);
}

.#{$fa-css-prefix}-stack-1x,
.#{$fa-css-prefix}-stack-2x {
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
}

.#{$fa-css-prefix}-stack-1x {
	line-height: inherit;
}

.#{$fa-css-prefix}-stack-2x {
	font-size: 2em;
}

.#{$fa-css-prefix}-inverse {
	color: $fa-inverse;
}
